import html2canvas from "html2canvas";
import * as printJS from  "print-js";
// import * as download from "downloadjs";

export const PrintPage = () => {
    const body = document.getElementsByTagName('body')[0];
    const height = document.documentElement.style.height;
    body.style.height = 'auto';
    html2canvas(body, {
        useCORS: true,
        allowTaint: true,
    }).then((canvas) => {
        // Might want to add option for downloading image.
        // download(canvas.toDataURL('image/png'), 'test.png');
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = canvas.toDataURL('image/png');
        img.id = 'print-page-id';
        const div = document.createElement("div");
        div.append(img);
        img.onload = () => {
            printJS( {
                printable: div,
                type: 'html',
                scanStyles: true,
                style: '#print-page-id { max-width:100%; height:auto; } div { position: absolute; top: 0; }'
            });
        }
        img.onerror = () => {
            console.log('ERROR!');
        }
    }).finally(() => {
        body.style.height = height;
    });
}

declare global {
    interface Window {
        PrintPage: typeof PrintPage
    }
}

window.PrintPage = PrintPage;
